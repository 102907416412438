import Tooltip from "ds_legacy/components/Tooltip";
import { ICON_DARK } from "ds_legacy/materials/colors";
import { LockIcon } from "lucide-react";
import React from "react";

export function LockSecurePin({
  style,
  tooltip,
}: {
  style?: React.CSSProperties;
  tooltip?: string;
}) {
  const Pin = (
    <LockIcon style={{ color: ICON_DARK, ...style }} size={style?.fontSize} />
  );

  return (
    <Tooltip title={tooltip} tooltipTextAlign="center">
      {Pin}
    </Tooltip>
  );
}
