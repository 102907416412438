import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_YES, REHAB_DIRECTIVE_TYPE } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const RehabDirective = () => {
  const translations = useTranslations();

  const REHAB_DIRECTIVE_TYPE_ITEMS: RadioOptionV2[] = [
    {
      id: REHAB_DIRECTIVE_TYPE.MYOCARDIAL_INFARCTION,
      value: REHAB_DIRECTIVE_TYPE.MYOCARDIAL_INFARCTION,
      label:
        translations.patientForms.medicalForm.sectionFive.myocardialInfarction,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.BYPASS,
      value: REHAB_DIRECTIVE_TYPE.BYPASS,
      label: translations.patientForms.medicalForm.sectionFive.bypass,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.HEART_FAILURE,
      value: REHAB_DIRECTIVE_TYPE.HEART_FAILURE,
      label: translations.patientForms.medicalForm.sectionFive.heartFailure,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.ENDOPROSTHETIC_TREATMENT,
      value: REHAB_DIRECTIVE_TYPE.ENDOPROSTHETIC_TREATMENT,
      label:
        translations.patientForms.medicalForm.sectionFive
          .endoprostheticTreatment,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.BACK_PAIN,
      value: REHAB_DIRECTIVE_TYPE.BACK_PAIN,
      label: translations.patientForms.medicalForm.sectionFive.backPain,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.SPINAL_CANAL,
      value: REHAB_DIRECTIVE_TYPE.SPINAL_CANAL,
      label: translations.patientForms.medicalForm.sectionFive.spinalCanal,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.AMPUTATION,
      value: REHAB_DIRECTIVE_TYPE.AMPUTATION,
      label: translations.patientForms.medicalForm.sectionFive.amputation,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.RESPIRATORY_DISEASE,
      value: REHAB_DIRECTIVE_TYPE.RESPIRATORY_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.respiratoryDisease,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.NEUROLOGICAL_DISEASE,
      value: REHAB_DIRECTIVE_TYPE.NEUROLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.neurologicalDiseases,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.ONCOLOGICAL_DISEASE,
      value: REHAB_DIRECTIVE_TYPE.ONCOLOGICAL_DISEASE,
      label:
        translations.patientForms.medicalForm.sectionFive.oncologicalDisease,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.TRANSPLANTS,
      value: REHAB_DIRECTIVE_TYPE.TRANSPLANTS,
      label: translations.patientForms.medicalForm.sectionFive.transplants,
    },
    {
      id: REHAB_DIRECTIVE_TYPE.GERIATRIC_REHAB,
      value: REHAB_DIRECTIVE_TYPE.GERIATRIC_REHAB,
      label: translations.patientForms.medicalForm.sectionFive.geriatricRehab,
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionFive.rehabRl}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="rehab_directive_state"
        label={translations.patientForms.medicalForm.sectionFive.rehabRl}
        formLabelSx={visuallyHidden}
        startWithNo
        sideMutation={(_value, mutate) => {
          mutate(null, "rehab_directive_type");
        }}
        subForms={{
          [PREDICAMENT_STATE_YES]: (
            <RadioGroupV2
              elementName="rehab_directive_type"
              flatModel
              label={
                translations.patientForms.medicalForm.sectionFive
                  .rehabDirectiveTypeLabel
              }
              options={REHAB_DIRECTIVE_TYPE_ITEMS}
              formControlLabelSx={{
                paddingBottom: sizing(0.5),
                margin: margin(0, 0, 0, 4),
              }}
              formLabelSx={visuallyHidden as SxProps}
              radioSx={RADIO_WHITE_BACKGROUND}
            />
          ),
        }}
      />
    </>
  );
};
