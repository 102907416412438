import Chip, { ChipProps } from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { CSSTextAlign } from "core/types";
import Tooltip from "ds_legacy/components/Tooltip";
import { dp, sizing } from "ds_legacy/materials/metrics";
import {
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
} from "ds_legacy/materials/typography";
import { useMemo } from "react";

export type InfoChipProps = Pick<
  ChipProps,
  "color" | "disabled" | "icon" | "label" | "size" | "sx" | "variant"
> & {
  as?: React.ElementType;
  bold?: boolean;
  margin?: string;
  testId?: string;
  tooltip?: string;
  tooltipTextAlign?: CSSTextAlign;
};

export default function InfoChip({
  as,
  bold,
  color = "default",
  disabled = false,
  icon,
  label,
  margin,
  size = "small",
  sx,
  testId,
  tooltip,
  tooltipTextAlign = "center",
  variant = "filled",
}: InfoChipProps) {
  const theme = useTheme();
  const paletteKey = color === "default" ? "info" : color;

  const sxConfig = useMemo(
    () => ({
      borderColor:
        variant === "outlined" && color === "default"
          ? theme.palette.grey[600]
          : theme.palette[paletteKey].main,
      "& .MuiChip-icon": {
        marginLeft: sizing(1),
      },
      "& .MuiChip-label": {
        fontWeight: bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR,
        whiteSpace: "normal",
      },
      "&.MuiChip-colorSecondary": {
        color: theme.palette.common.black,
        borderColor:
          variant === "outlined"
            ? theme.palette[paletteKey].main
            : theme.palette.common.white,
        backgroundColor:
          variant === "outlined"
            ? theme.palette.common.white
            : theme.palette[paletteKey].main,
      },
      "&.MuiChip-colorSuccess": {
        color:
          variant === "outlined"
            ? theme.palette[paletteKey].main
            : theme.palette.common.white,
        borderColor:
          variant === "outlined"
            ? theme.palette[paletteKey].main
            : theme.palette.common.white,
        backgroundColor:
          variant === "outlined"
            ? theme.palette.common.white
            : theme.palette[paletteKey].main,
      },
      ...sx,
    }),
    [bold, color, variant, theme, ...Object.values(sx ?? {})],
  );

  const Container = as ?? "div";

  return (
    <Container data-testid={testId} style={{ margin: margin }}>
      <Tooltip title={tooltip} tooltipTextAlign={tooltipTextAlign}>
        <Chip
          color={color}
          disabled={disabled}
          icon={icon}
          label={label}
          size={size}
          sx={{
            ...sxConfig,
            height: "auto",
            minHeight: dp(24),
            wordBreak: "break-word",
          }}
          variant={variant}
        />
      </Tooltip>
    </Container>
  );
}
