import { TRACK_EVENTS } from "core/consts";
import { getKey } from "core/model/utils/strings";
import LinkComponent from "ds_legacy/components/Link";
import { margin } from "ds_legacy/materials/metrics";
import { Body, Caption, FONT_SIZE_12 } from "ds_legacy/materials/typography";
import { EventSpecMessage } from "dsl/atoms/EventSpecs";
import { ChevronRightIcon } from "lucide-react";
import { CSSProperties } from "react";
import { To, useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";

const BubbleContainer = styled.div<{
  column?: boolean;
  right?: boolean;
  withMargin?: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1 1 0px;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
  align-items: ${(props) =>
    props.column && !props.right ? "flex-start" : "flex-end"};
  opacity: 1;
  margin-bottom: ${(props) => (props.withMargin ? "7px" : undefined)};
`;

export const BodyWrapper = styled(Body)`
  margin: ${margin(1, 1.5)};
  word-wrap: break-word;
  max-width: 100%;
`;

export function MessengerSpecMessage({
  eventMessage,
  textAlign,
  whiteSpace,
}: {
  eventMessage: Exclude<EventSpecMessage, string> | undefined;
  textAlign?: CSSProperties["textAlign"];
  whiteSpace?: CSSProperties["whiteSpace"];
}) {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  if (!eventMessage || typeof eventMessage === "string") return null;

  return (
    <>
      {eventMessage.title && (
        <Body margin={margin(0)} textAlign={textAlign} whiteSpace="pre-wrap">
          {eventMessage.title}
        </Body>
      )}
      {eventMessage.date && (
        <Caption
          margin={margin(0, 1.25, 0, 1.25)}
          textAlign={textAlign}
          whiteSpace={whiteSpace}
        >
          {eventMessage.date}
        </Caption>
      )}
      {eventMessage.date && eventMessage.time && (
        <Caption
          margin={margin(0, 1.25, 0, 1.25)}
          textAlign={textAlign}
          whiteSpace={whiteSpace}
        >
          {eventMessage.time}
        </Caption>
      )}
      {eventMessage.reasons?.map((reason, i) => {
        return (
          <Body
            key={getKey(reason, i)}
            margin={margin(0)}
            textAlign={textAlign}
            whiteSpace="pre-wrap"
          >
            {reason}
          </Body>
        );
      })}
      {eventMessage.link && (
        <LinkComponent
          fontSize={FONT_SIZE_12}
          Icon={ChevronRightIcon}
          value={eventMessage.link.label}
          onAction={() => {
            trackEvent({
              name: TRACK_EVENTS.MESSENGER_SEE_DOCUMENT_LINK,
            });
            navigate(eventMessage.link?.to as To);
          }}
        />
      )}
    </>
  );
}

export function EventMessage({
  eventMessages,
  right,
}: {
  eventMessages?: EventSpecMessage[];
  right?: boolean;
}) {
  if (!eventMessages) return null;

  return (
    <>
      {eventMessages?.map((eventMessage, idx) => (
        <BubbleContainer key={idx.toString()} right={right} withMargin column>
          <MessengerSpecMessage
            eventMessage={eventMessage}
            textAlign={right ? "right" : "left"}
          />
        </BubbleContainer>
      ))}
    </>
  );
}
