import { useState } from "react";
import { ConfirmationDialogProps } from ".";

type ConfirmParameters = Omit<
  Partial<ConfirmationDialogProps>,
  "queryProgress"
>;

const defaultProps: ConfirmationDialogProps = {
  onCancel: () => {},
  onSubmit: () => {},
  opened: false,
  title: "",
};

/**
 * Allows to use an existing `ConfirmationDialog` component in a programmatic
 * way (similar to the browser's window.confirm), by calling a `confirm` function
 * that will return a Promise representing the result of the user interaction with the dialog.
 * This also enables an easy way to reuse one single confirmation dialog among
 * multiple members of a containing component.
 *
 * The `confirm` function can optionally receive in its `dialogSettings` parameter any of the regular
 * props that you can pass to a `ConfirmationDialog` component, allowing to customise the
 * information displayed by the dialog in every separate `confirm` call.
 *
 * NOTE: Asynchronous confirmation is not compatible with external state passed to the
 * dialog through `queryProgress`. The dialog will close immediately after choosing either
 * to confirm or to cancel. It's up to the implementor to decide what to do with the
 * confirmation or rejection.
 *
 * @returns  Tuple containing the props needed to manipulate a `ConfirmationDialog` component as first member, and the function that triggers the display of such Dialog as second member
 */
export function useConfirmationDialog() {
  const [props, setProps] = useState<ConfirmationDialogProps>(defaultProps);

  function confirm(dialogSettings: ConfirmParameters = {}): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      function resolveDialog(result: boolean) {
        resolve(result);
        setProps((current) => ({ ...current, opened: false }));
      }

      setProps((current) => ({
        ...current,
        ...dialogSettings,
        opened: true,
        onCancel: () => {
          // Allows the implementor to pass bespoke functionality (like tracking events)
          // on Cancel when calling `confirm`, without breaking the internal behaviour.
          // The same applies to onSubmit underneath.
          dialogSettings.onCancel?.();
          resolveDialog(false);
        },
        onSubmit: () => {
          dialogSettings.onSubmit?.();
          resolveDialog(true);
        },
      }));
    });

    return promise;
  }

  return [props, confirm] as const;
}
