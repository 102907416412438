import { QuerySignature } from "apollo/utils";
import { createContext, useContext } from "react";

export const AuctionRequestEventsContext = createContext<{
  eventsQuery?: QuerySignature;
  refetchEvents?: () => void;
}>({});

export const useAuctionRequestEventsContext = () =>
  useContext(AuctionRequestEventsContext);
