import Chip from "@mui/material/Chip";
import { emphasize } from "@mui/material/styles";
import { styled } from "@mui/system";
import { dp } from "ds_legacy/materials/metrics";
import { FONT_SIZE_24 } from "ds_legacy/materials/typography";
import { XCircleIcon } from "lucide-react";
import { MultiValueProps } from "react-select";
import { SelectOption, SelectType } from "./types";

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !["isFocused"].includes(prop as string),
})<Pick<SelectType, "value"> & { isFocused: boolean | undefined }>(
  ({ isFocused, theme }) => ({
    margin: theme.spacing(1 / 2, 1 / 4),
    background: isFocused
      ? theme.palette.mode === "light"
        ? emphasize(theme.palette.grey[300], 0.08)
        : emphasize(theme.palette.grey[600], 0.08)
      : undefined,
  }),
);

export function MultiValue({
  children,
  data,
  isFocused,
  removeProps,
  selectProps: { useShortValue },
}: MultiValueProps<SelectOption>) {
  const displayValue = `${useShortValue ? data.value : data.label}`;
  return (
    <StyledChip
      isFocused={isFocused}
      tabIndex={-1}
      label={displayValue || children}
      onDelete={removeProps.onClick}
      deleteIcon={
        <div>
          <XCircleIcon
            style={{ width: dp(24), height: dp(24) }}
            fontSize={FONT_SIZE_24}
            {...(removeProps as any)}
          />
        </div>
      }
    />
  );
}
