import { FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import { forwardRef } from "react";
import { ControlProps } from "react-select";
import { SelectOption } from "./types";

const inputComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div ref={ref} {...props} style={{ display: "flex", height: "100%" }} />
  );
});

export function Control({
  children,
  innerProps,
  innerRef,
  selectProps: {
    classes,
    customNoResult,
    hasError,
    options,
    searchInputValue,
    textFieldProps,
  },
}: ControlProps<SelectOption>) {
  return (
    <>
      <TextField
        variant="standard"
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: classNames(
              classes.input,
              hasError && classes.inputError,
            ),
            ref: innerRef,
            children,
            ...(innerProps as any),
          },
        }}
        {...textFieldProps}
      />
      {customNoResult &&
        options.length === 0 &&
        searchInputValue.length >= customNoResult.minChars && (
          <FormHelperText>{customNoResult.message}</FormHelperText>
        )}
    </>
  );
}
