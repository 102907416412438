import React from "react";
import { composeTranslation } from "./options";

type MyAccountFn = (props: {
  providerName: string;
  receiverTypeSingularPreferred: string;
}) => string;

type OtherAccountFn = (props: {
  accountName: string;
  providerName: string;
  receiverTypeSingularPreferred: string;
}) => string;

const checkForFunction = (
  valueOrFunction: string | MyAccountFn,
  args: Parameters<MyAccountFn>[0],
) =>
  typeof valueOrFunction === "function"
    ? valueOrFunction(args)
    : valueOrFunction;

export const TranslationComposition = ({
  children,
  translations,
  withOptions = false,
}: {
  children?: (translationChunks: Array<string>) => React.ReactNode;
  translations: string;
  withOptions?: boolean;
}) => {
  const values =
    withOptions && children
      ? composeTranslation(translations)
      : Object.values(translations);
  const renderedChildren = children ? children(values) : null;

  return (
    <>
      {renderedChildren ||
        values.map(
          (value, idx) =>
            typeof value == "string" && (
              <span key={idx.toString()}>
                {value}
                <br />
              </span>
            ),
        )}
    </>
  );
};

export const getTranslationForAccount = (
  translations: {
    myAccount: string | MyAccountFn;
    otherAccount: OtherAccountFn;
  },
  account?: string | null,
  provider?: string | null,
  otherArgs: { receiverTypeSingularPreferred: string } = {
    receiverTypeSingularPreferred: "",
  },
) => {
  const providerName = provider || "";
  return account
    ? translations.otherAccount({
        accountName: account,
        providerName,
        ...otherArgs,
      })
    : checkForFunction(translations.myAccount, { providerName, ...otherArgs });
};
