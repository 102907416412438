import Tooltip from "ds_legacy/components/Tooltip";
import { SingleValueProps } from "react-select";
import { SelectOption } from "./types";

export function SingleValue({
  children,
  innerProps,
  selectProps: { classes },
}: SingleValueProps<SelectOption> & { isFocused?: boolean }) {
  return (
    <Tooltip title={children}>
      <p className={classes.singleValue} {...(innerProps as any)}>
        {children}
      </p>
    </Tooltip>
  );
}
