import { FUNCTION_DIAGNOSIS_PRIORITIES } from "core/consts";
import { validateCharacterLimit } from "core/model/utils/strings";
import {
  BarthelAssessment,
  EarlyRehabAssessment,
  EarlyRehabCriteria,
  Gender,
  MuskuloskeletalResilience,
  PatientTransport,
  PerformedTreatment,
  PredicamentState,
  RehabAdmission,
  RehabDirectiveType,
  RehabFocus,
  SingerAssessment,
  TransportTaxi,
  TransportType,
  TypeOfRehab,
  YesNoFieldOption,
} from "core/types";
import { SelectOption } from "ds_legacy/components/SelectInput";
import {
  validateSingerAssessmentDate,
  validateSingerAssessmentScore,
} from "dsl/organisms/IndependenceScale/validators";
import { convertModelDefinition, valueDef } from "react-forms-state";
import {
  ICD_CODE_CHARACTER_LIMIT,
  INFECTIONS_CHARACTER_LIMIT,
} from "../../utils";

type RehaClinicInfo = {
  needs_dialysis?: string | null;
  needs_obesity?: boolean | null;
  needs_peg_tube?: boolean | null;
  needs_tracheostomy?: boolean | null;
  needs_urinary_catheter?: boolean | null;
  requirement_artificial_respiration?: boolean | null;
  requirement_bed_overlength?: boolean | null;
  requirement_chemotherapy?: boolean | null;
  requirement_immunosuppressants?: boolean | null;
  requirement_leg_prothesis?: boolean | null;
  requirement_other?: string | null;
  requirement_reha_clinic?: string | null;
  requirement_relative_care?: boolean | null;
  requirement_severe_sense_impairment?: boolean | null;
  requirement_weaning?: boolean | null;
  requires_isolation?: boolean | null;
};

type ComplicationInTreatmentProcess = {
  complication_cause_cardiovascular?: string | null;
  complication_cause_maindiagnosis?: string | null;
  complication_cause_other?: string | null;
};

export type MedicalFormUniversalDataOld = {
  _complication_in_treatment_process?: number | null;
  _reha_clinic_info?: YesNoFieldOption | null;
  admission_date?: number | null;
  barthel_assessment?: BarthelAssessment | null;
  bathing?: number | null;
  behavioural_disorder?: number | null;
  complication_in_treatment_process?: ComplicationInTreatmentProcess | null;
  current_therapy?: string | null;
  direct_transfer_necessary?: boolean | null;
  discharge_date?: number | null;
  doctor_in_charge_in_hospital?: string | null;
  doctor_in_charge_in_hospital_phone?: string | null;
  dressing_and_undressing?: number | null;
  early_rehab_assessment?: EarlyRehabAssessment | null;
  early_rehabilitation_measures?: YesNoFieldOption | null;
  fecal_incontinence?: number | null;
  food?: number | null;
  gender?: Gender | null;
  getting_up_and_walking?: number | null;
  icd_code_1_code?: string | null;
  icd_code_1_diagnosis?: string | null;
  icd_code_1_localisation?: string | null;
  icd_code_1_reason?: SelectOption | null;
  icd_code_2_code?: string | null;
  icd_code_2_diagnosis?: string | null;
  icd_code_2_localisation?: string | null;
  icd_code_2_reason?: SelectOption | null;
  icd_code_3_code?: string | null;
  icd_code_3_diagnosis?: string | null;
  icd_code_3_localisation?: string | null;
  icd_code_3_reason?: SelectOption | null;
  icd_code_4_code?: string | null;
  icd_code_4_diagnosis?: string | null;
  icd_code_4_localisation?: string | null;
  icd_code_4_reason?: SelectOption | null;
  icd_code_5_code?: string | null;
  icd_code_5_diagnosis?: string | null;
  icd_code_5_localisation?: string | null;
  icd_code_5_reason?: SelectOption | null;
  id?: number | string | null;
  indication_cardiology?: boolean | null;
  indication_geriatrics?: boolean | null;
  indication_musculoskeletal_diseases?: boolean | null;
  indication_neurology?: boolean | null;
  indication_other?: string | null;
  infections?: string | 0 | null;
  insurance_number?: string | null;
  intensive_medical_monitoring?: number | null;
  interim_prosthesis_fitted?: YesNoFieldOption | null;
  intermittent_ventilation?: number | null;
  limited_capacity?: YesNoFieldOption | null;
  living_special?: string | null;
  no_direct_transfer_necessary_explanation?: string | null;
  operation_date?: number | null;
  operation_description?: string | null;
  operations?: string | null;
  orientation_disorder?: number | null;
  patient_birthdate?: string | null;
  patient_first_name?: string | null;
  patient_last_name?: string | null;
  personal_care?: string | null;
  prefered_clinic_contacted?: string | null;
  reason_for_reha_clinic?: string | null;
  recommended_reha_type_ambulant?: boolean | null;
  recommended_reha_type_ambulant_mobil?: string | null;
  recommended_reha_type_stationary?: string | null;
  reha_capacity_full?: boolean | null;
  reha_capacity_other?: string | null;
  reha_capacity_partial?: string | null;
  reha_clinic_info?: RehaClinicInfo | null;
  rehabilitation_goals?: string | null;
  require_accompanying_person?: YesNoFieldOption | null;
  retirement_earning_capacity_company?: string | null;
  setting_up_and_relocating?: number | null;
  severe_communication_disorder?: number | null;
  singer_assessment?: SingerAssessment | null;
  stable_during_exercise?: boolean | null;
  stair_climbing?: number | null;
  supervised_swallowing_disorder?: number | null;
  support_self_sufficiency?: number | null;
  threats_areas_life?: string | null;
  threats_communication?: string | null;
  threats_community_life?: string | null;
  threats_domestic_life?: string | null;
  threats_general_tasks?: string | null;
  threats_learning?: string | null;
  threats_mobility?: string | null;
  threats_relationships?: string | null;
  threats_self_sufficiency?: string | null;
  toilet_use?: number | null;
  tracheostoma_requiring_aspiration?: number | null;
  transfer_date?: number | null;
  transport_type?: PatientTransport["transport_type"] | null;
  urinary_incontinence?: number | null;
  wound_closed?: YesNoFieldOption | null;
};

export type MedicalFormUniversalData = {
  admission_date?: number | null;
  complication_cause_cardiovascular?: boolean | null;
  complication_cause_cardiovascular_description?: string | null;
  complication_cause_maindiagnosis?: boolean | null;
  complication_cause_maindiagnosis_description?: string | null;
  complication_cause_other?: boolean | null;
  complication_cause_other_description?: string | null;
  complication_in_treatment_process?: PredicamentState | null;
  current_therapy?: string | null;
  discharge_date?: number | null;
  doctor_in_charge_in_hospital?: string | null;
  doctor_in_charge_in_hospital_phone?: string | null;
  early_rehabilitation_measures?: PredicamentState | null;
  gender?: Gender | null;
  icd_code_1_code?: string | null;
  icd_code_1_diagnosis?: string | null;
  icd_code_1_reason?: string | null;
  icd_code_2_code?: string | null;
  icd_code_2_diagnosis?: string | null;
  icd_code_2_reason?: string | null;
  icd_code_3_code?: string | null;
  icd_code_3_diagnosis?: string | null;
  icd_code_3_reason?: string | null;
  icd_code_4_code?: string | null;
  icd_code_4_diagnosis?: string | null;
  icd_code_4_reason?: string | null;
  icd_code_5_code?: string | null;
  icd_code_5_diagnosis?: string | null;
  icd_code_5_reason?: string | null;
  infections?: string | null;
  infections_state?: PredicamentState | null;
  insurance_number?: string | null;
  interim_prosthesis_fitted?: PredicamentState | null;
  limited_capacity?: PredicamentState | null;
  operation_date?: number | null;
  operation_description?: string | null;
  operations?: string | null;
  patient_birthdate?: number | null;
  patient_first_name?: string | null;
  patient_last_name?: string | null;
  performed_treatment?: PerformedTreatment | null;
  recommended_reha_type?: TypeOfRehab | null;
  recommended_reha_type_ambulant_mobil?: string | null;
  recommended_reha_type_stationary?: string | null;
  reha_capacity?: MuskuloskeletalResilience | null;
  reha_capacity_early_rehab_criteria?: EarlyRehabCriteria[] | null;
  reha_capacity_other?: string | null;
  reha_capacity_partial?: string | null;
  rehab_admission_outside_two_weeks_explanation?: string | null;
  rehab_admission_outside_two_weeks_start_date?: number | null;
  rehab_admission_type?: RehabAdmission | null;
  rehab_admission_within_two_weeks_start_date?: number | null;
  rehab_directive_state?: PredicamentState | null;
  rehab_directive_type?: RehabDirectiveType[] | null;
  rehab_focus?: RehabFocus[] | null;
  rehab_focus_other_description?: string | null;
  rehabilitation_goals?: string | null;
  require_accompanying_person?: PredicamentState | null;
  singer_assessment_date?: SingerAssessment["date"] | null;
  singer_assessment_form?: SingerAssessment["form"] | null;
  singer_assessment_score?: SingerAssessment["score"] | null;
  special_requirements_catheter?: boolean | null;
  special_requirements_chemotherapy?: boolean | null;
  special_requirements_decubitus?: boolean | null;
  special_requirements_decubitus_description?: string | null;
  special_requirements_dialysis?: boolean | null;
  special_requirements_dialysis_description?: string | null;
  special_requirements_immunosuppressants?: boolean | null;
  special_requirements_impairment?: boolean | null;
  special_requirements_isolation?: boolean | null;
  special_requirements_obesity?: boolean | null;
  special_requirements_other?: boolean | null;
  special_requirements_other_description?: string | null;
  special_requirements_overlength_bed?: boolean | null;
  special_requirements_peg?: boolean | null;
  special_requirements_prosthesis?: boolean | null;
  special_requirements_relatives?: boolean | null;
  special_requirements_relatives_description?: string | null;
  special_requirements_required?: PredicamentState | null;
  special_requirements_tracheostoma?: boolean | null;
  special_requirements_ventilation?: boolean | null;
  special_requirements_weaning?: boolean | null;
  special_requirements_weight_bed?: boolean | null;
  special_requirements_weight_bed_description?: string | null;
  transport_type?: TransportType | null;
  transport_type_ambulance_description?: string | null;
  transport_type_taxi_specification?: TransportTaxi | null;
  wound_closed?: PredicamentState | null;
};

const valueDefFunctionalDiagnosis = (priority: number) => {
  return {
    ...valueDef(`icd_code_${priority}_diagnosis`),
    ...valueDef(`icd_code_${priority}_code`, {
      validate: (value, props) =>
        validateCharacterLimit(ICD_CODE_CHARACTER_LIMIT)(value, props),
    }),
    ...valueDef(`icd_code_${priority}_reason`),
  };
};

export const medicalFormUniversalDefinition = convertModelDefinition({
  // section one
  ...valueDef("gender"),
  ...valueDef("patient_last_name"),
  ...valueDef("patient_first_name"),
  ...valueDef("insurance_number"),
  ...valueDef("patient_birthdate"),
  ...valueDef("admission_date"),
  ...valueDef("discharge_date"),
  ...valueDef("early_rehabilitation_measures"),
  ...valueDef("rehab_admission_type"),
  ...valueDef("rehab_admission_within_two_weeks_start_date"),
  ...valueDef("rehab_admission_outside_two_weeks_start_date"),
  ...valueDef("rehab_admission_outside_two_weeks_explanation"),

  // section two
  ...valueDefFunctionalDiagnosis(FUNCTION_DIAGNOSIS_PRIORITIES.PRIO_ONE),
  ...valueDefFunctionalDiagnosis(FUNCTION_DIAGNOSIS_PRIORITIES.PRIO_TWO),
  ...valueDefFunctionalDiagnosis(FUNCTION_DIAGNOSIS_PRIORITIES.PRIO_THREE),
  ...valueDefFunctionalDiagnosis(FUNCTION_DIAGNOSIS_PRIORITIES.PRIO_FOUR),
  ...valueDefFunctionalDiagnosis(FUNCTION_DIAGNOSIS_PRIORITIES.PRIO_FIVE),
  ...valueDef("performed_treatment"),
  ...valueDef("operation_date"),
  ...valueDef("operations"),
  ...valueDef("operation_description"),
  ...valueDef("wound_closed"),
  ...valueDef("current_therapy"),
  ...valueDef("complication_in_treatment_process"),
  ...valueDef("complication_cause_maindiagnosis"),
  ...valueDef("complication_cause_maindiagnosis_description"),
  ...valueDef("complication_cause_cardiovascular"),
  ...valueDef("complication_cause_cardiovascular_description"),
  ...valueDef("complication_cause_other"),
  ...valueDef("complication_cause_other_description"),
  ...valueDef("infections_state"),
  ...valueDef("infections", {
    validate: (value, props) =>
      validateCharacterLimit(INFECTIONS_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("singer_assessment_score", {
    validate: (value, props, globals) =>
      validateSingerAssessmentScore(value, props, globals as any),
  }),
  ...valueDef("singer_assessment_date", {
    validate: validateSingerAssessmentDate,
  }),
  ...valueDef("singer_assessment_form"),
  // section three
  ...valueDef("limited_capacity"),
  ...valueDef("reha_capacity"),
  ...valueDef("reha_capacity_partial"),
  ...valueDef("interim_prosthesis_fitted"),
  ...valueDef("reha_capacity_other"),
  ...valueDef("reha_capacity_early_rehab_criteria"),

  // section four
  ...valueDef("rehabilitation_goals"),

  // section five
  ...valueDef("rehab_focus"),
  ...valueDef("rehab_focus_other_description"),
  ...valueDef("recommended_reha_type"),
  ...valueDef("recommended_reha_type_ambulant_mobil"),
  ...valueDef("recommended_reha_type_stationary"),
  ...valueDef("rehab_directive_state"),
  ...valueDef("rehab_directive_type"),

  // section six
  ...valueDef("special_requirements_required"),
  ...valueDef("special_requirements_dialysis"),
  ...valueDef("special_requirements_dialysis_description"),
  ...valueDef("special_requirements_chemotherapy"),
  ...valueDef("special_requirements_immunosuppressants"),
  ...valueDef("special_requirements_catheter"),
  ...valueDef("special_requirements_peg"),
  ...valueDef("special_requirements_isolation"),
  ...valueDef("special_requirements_ventilation"),
  ...valueDef("special_requirements_tracheostoma"),
  ...valueDef("special_requirements_weaning"),
  ...valueDef("special_requirements_prosthesis"),
  ...valueDef("special_requirements_overlength_bed"),
  ...valueDef("special_requirements_obesity"),
  ...valueDef("special_requirements_impairment"),
  ...valueDef("special_requirements_weight_bed"),
  ...valueDef("special_requirements_weight_bed_description"),
  ...valueDef("special_requirements_relatives"),
  ...valueDef("special_requirements_relatives_description"),
  ...valueDef("special_requirements_decubitus"),
  ...valueDef("special_requirements_decubitus_description"),
  ...valueDef("special_requirements_other"),
  ...valueDef("special_requirements_other_description"),
  ...valueDef("transport_type"),
  ...valueDef("transport_type_taxi_specification"),
  ...valueDef("transport_type_ambulance_description"),
  ...valueDef("require_accompanying_person"),
  ...valueDef("doctor_in_charge_in_hospital"),
  ...valueDef("doctor_in_charge_in_hospital_phone"),
});
