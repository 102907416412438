import { PlaceholderProps } from "react-select";
import { SelectOption } from "./types";

export function Placeholder({
  children,
  innerProps,
  selectProps: { classes },
}: PlaceholderProps<SelectOption>) {
  return (
    <p className={classes.placeholder} {...(innerProps as any)}>
      {children}
    </p>
  );
}
