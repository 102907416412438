import { validateZipcode } from "core/model/utils/location";
import { validateCharacterLimit } from "core/model/utils/strings";
import {
  CareLevel,
  OutPatientNotPossibleReason,
  PatientTransportType,
  PredicamentState,
  RehabTriggeringEvent,
  YesNoFieldOption,
} from "core/types";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { convertModelDefinition, ibanDef, valueDef } from "react-forms-state";
import {
  DRG_CODE_CHARACTER_LIMIT,
  ICD_CODE_CHARACTER_LIMIT,
  IK_NUMBER_CHARACTER_LIMIT,
  INFECTIONS_CHARACTER_LIMIT,
  PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT,
} from "../../utils";

export type MedicalFormPensionDataOld = {
  _further_therapeutic_diagnostic_measures?: YesNoFieldOption;
  admission_date?: number | null;
  clinical_findings?: string | null;
  complications?: string | null;
  current_therapy?: string | null;
  day_reha?: boolean | null;
  discharge_date?: number | null;
  drg_code?: string | null;
  drv_remarks?: string | null;
  eat_without_help?: YesNoFieldOption | null;
  fecal_incontinence?: YesNoFieldOption | null;
  full_reha?: boolean | null;
  further_therapeutic_diagnostic_measures?: {
    further_therapeutic_diagnostic_measures_description?: string | null;
    further_therapeutic_diagnostic_measures_finished_on?: string | null;
    further_therapeutic_diagnostic_measures_from?: string | null;
    further_therapeutic_diagnostic_measures_to?: string | null;
  } | null;
  had_surgery?: boolean;
  hospital_bank_holder?: string | null;
  hospital_bank_name?: string | null;
  hospital_billing_address?: string | null;
  hospital_iban?: string | null;
  hospital_ik_number?: string | null;
  hospital_name_address?: string | null;
  icd_code_1_code?: string | null;
  icd_code_2_code?: string | null;
  icd_code_3_code?: string | null;
  infections?: string | 0 | null;
  insurance_delivery_address?: SelectOption | null;
  main_diagnosis?: string | null;
  mobility_walk_without_help?: YesNoFieldOption | null;
  mobility_wheel_chair?: YesNoFieldOption | null;
  needs_catheter?: string | 0 | null;
  orientation_disoriented?: string | 0 | null;
  patient_birthdate?: string | null;
  patient_city_zipcode?: string | null;
  patient_height?: string | null;
  patient_name?: string | null;
  patient_street_housenumber?: string | null;
  patient_weight?: string | null;
  pension_procedure_after_examination?: boolean | null;
  pension_procedure_direct?: boolean | null;
  preferred_rehab_clinic?: string | null;
  rehab_triggering_event_caused_by_other?: boolean | null;
  rehab_triggering_event_date?: string | null;
  rehab_triggering_event_last_antineoplastic_therapy?: boolean | null;
  rehab_triggering_event_last_radiation?: boolean | null;
  rehab_triggering_event_nothing?: boolean | null;
  rehab_triggering_event_other?: string | null;
  rehab_triggering_event_work_accident_illness?: boolean | null;
  require_accompanying_person?: boolean | null;
  secondary_diagnosis?: string | 0 | null;
  social_worker_name_number?: string | null;
  statutory_pension_insurance_number?: string | null;
  transfer_date?: number | null;
  transport_type?: PatientTransportType[] | null;
  urinary_incontinence?: YesNoFieldOption | null;
  wash_and_dress_without_help?: YesNoFieldOption | null;
  wound_closed?: YesNoFieldOption | null;
};

export type MedicalFormPensionData = {
  admission_date?: number | null;
  carelevel?: CareLevel | null;
  carelevel_selected?: PredicamentState | null;
  clinical_findings?: string | null;
  complications?: string | null;
  current_therapy?: string | null;
  day_reha?: boolean | null;
  discharge_date?: number | null;
  drg_code?: string | null;
  drv_remarks?: string | null;
  eat_without_help?: PredicamentState | null;
  fecal_incontinence?: PredicamentState | null;
  full_reha?: boolean | null;
  further_therapeutic_diagnostic_measures_description?: string | null;
  further_therapeutic_diagnostic_measures_finished_on?: number | null;
  further_therapeutic_diagnostic_measures_from?: number | null;
  further_therapeutic_diagnostic_measures_to?: number | null;
  hospital_bank_holder?: string | null;
  hospital_bank_name?: string | null;
  hospital_billing_address?: string | null;
  hospital_iban?: string | null;
  hospital_ik_number?: string | null;
  hospital_name_address?: string | null;
  icd_code_1_code?: string | null;
  icd_code_2_code?: string | null;
  icd_code_3_code?: string | null;
  infections?: string | null;
  infections_selected?: PredicamentState | null;
  insurance_delivery_address?: string | null;
  main_diagnosis?: string | null;
  mobility_walk_without_help?: PredicamentState | null;
  mobility_wheel_chair?: PredicamentState | null;
  needs_catheter?: string | null;
  needs_catheter_selected?: PredicamentState | null;
  orientation_disoriented?: string | null;
  orientation_disoriented_selected?: PredicamentState | null;
  other_measures?: PredicamentState | null;
  outpatient_not_possible_reason?: OutPatientNotPossibleReason[] | null;
  patient_birthdate?: number | null;
  patient_city?: string | null;
  patient_height?: string | null;
  patient_name?: string | null;
  patient_street_housenumber?: string | null;
  patient_weight?: string | null;
  patient_zipcode?: string | null;
  pension_procedure_after_examination?: boolean | null;
  pension_procedure_direct?: boolean | null;
  preferred_rehab_clinic?: string | null;
  rehab_triggering_event?: RehabTriggeringEvent[] | null;
  rehab_triggering_event_date?: number | null;
  secondary_diagnosis?: string | null;
  secondary_diagnosis_selected?: PredicamentState | null;
  social_worker_name_number?: string | null;
  statutory_pension_insurance_number?: string | null;
  transfer_date?: number | null;
  transport_type?: PatientTransportType[] | null;
  urinary_incontinence?: PredicamentState | null;
  wash_and_dress_without_help?: PredicamentState | null;
  wound_closed?: PredicamentState | null;
};

export const medicalFormPensionDefinition = convertModelDefinition({
  // INTRO
  ...valueDef("statutory_pension_insurance_number", {
    validate: (value, props) =>
      validateCharacterLimit(PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT)(
        value,
        props,
      ),
  }),
  ...valueDef("hospital_name_address"),
  ...valueDef("social_worker_name_number"),
  ...valueDef("preferred_rehab_clinic"),
  ...valueDef("pension_procedure_direct"),
  ...valueDef("pension_procedure_after_examination"),
  ...valueDef("full_reha"),
  ...valueDef("day_reha"),
  ...valueDef("patient_name"),
  ...valueDef("patient_birthdate"),
  ...valueDef("patient_street_housenumber"),
  ...valueDef("patient_city"),
  ...valueDef("patient_zipcode", { validate: validateZipcode(false) }),
  ...valueDef("admission_date"),
  ...valueDef("rehab_triggering_event_date"),
  ...valueDef("rehab_triggering_event"),
  ...valueDef("discharge_date"),
  ...valueDef("transfer_date"),
  // SECTION ONE
  ...valueDef("main_diagnosis"),
  ...valueDef("icd_code_1_code", {
    validate: (value, props) =>
      validateCharacterLimit(ICD_CODE_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("drg_code", {
    validate: (value, props) =>
      validateCharacterLimit(DRG_CODE_CHARACTER_LIMIT)(value, props),
  }),
  // SECTION TWO
  ...valueDef("complications"),
  ...valueDef("current_therapy"),
  ...valueDef("other_measures"),
  ...valueDef("further_therapeutic_diagnostic_measures_description"),
  ...valueDef("further_therapeutic_diagnostic_measures_from"),
  ...valueDef("further_therapeutic_diagnostic_measures_to"),
  ...valueDef("further_therapeutic_diagnostic_measures_finished_on"),
  // SECTION THREE
  ...valueDef("secondary_diagnosis_selected"),
  ...valueDef("icd_code_2_code", {
    validate: (value, props) =>
      validateCharacterLimit(ICD_CODE_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("icd_code_3_code", {
    validate: (value, props) =>
      validateCharacterLimit(ICD_CODE_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("secondary_diagnosis"),
  // SECTION FOUR
  ...valueDef("clinical_findings"),
  ...valueDef("patient_weight"),
  ...valueDef("patient_height"),
  // SECTION FIVE
  ...valueDef("eat_without_help"),
  ...valueDef("wash_and_dress_without_help"),
  ...valueDef("mobility_walk_without_help"),
  ...valueDef("wound_closed"),
  ...valueDef("infections_selected"),
  ...valueDef("infections", {
    validate: (value, props) =>
      validateCharacterLimit(INFECTIONS_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("urinary_incontinence"),
  ...valueDef("needs_catheter_selected"),
  ...valueDef("needs_catheter"),
  ...valueDef("fecal_incontinence"),
  ...valueDef("mobility_wheel_chair"),
  ...valueDef("orientation_disoriented_selected"),
  ...valueDef("orientation_disoriented"),
  ...valueDef("carelevel_selected"),
  ...valueDef("carelevel"),
  ...valueDef("transport_type"),
  ...valueDef("outpatient_not_possible_reason"),
  // SECTION SIX
  ...valueDef("insurance_delivery_address"),
  ...valueDef("hospital_bank_holder"),
  ...valueDef("hospital_bank_name"),
  ...ibanDef("hospital_iban"),
  ...valueDef("hospital_ik_number", {
    validate: (value, props) =>
      validateCharacterLimit(IK_NUMBER_CHARACTER_LIMIT)(value, props),
  }),
  ...valueDef("hospital_billing_address"),
  // SECTION SEVEN
  ...valueDef("drv_remarks"),
});
