import { ValueContainerProps } from "react-select";
import { SelectOption } from "./types";

export function ValueContainer({
  children,
  isMulti,
  selectProps: { classes },
}: ValueContainerProps<SelectOption>) {
  return (
    <div
      className={
        !isMulti ? classes.valueContainerSingle : classes.valueContainerMulti
      }
    >
      <div
        className={
          !isMulti
            ? classes.valueContainerSingleItem
            : classes.valueContainerMultiItems
        }
      >
        {children}
      </div>
    </div>
  );
}
