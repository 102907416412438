import { RefObject, useLayoutEffect, useRef, useState } from "react";

function useHasOverFlown<Element extends HTMLElement>({
  direction = "x",
  value,
}: {
  direction: "x" | "y";
  value: string | undefined;
}): { hasOverFlown: boolean; ref: RefObject<Element> } {
  const ref = useRef<Element | null>(null);
  const [hasOverFlown, setHasOverFlown] = useState(false);

  useLayoutEffect(() => {
    function checkOverflow() {
      if (!value || ref.current == null) return;
      if (direction === "x") {
        setHasOverFlown(ref.current.scrollWidth > ref.current.clientWidth);
      } else if (direction === "y") {
        setHasOverFlown(ref.current.scrollHeight > ref.current.clientHeight);
      }
    }

    checkOverflow();

    window.addEventListener("resize", checkOverflow);

    return () => {
      setHasOverFlown(false);
      window.removeEventListener("resize", checkOverflow);
    };
  }, [value]);

  return { ref, hasOverFlown };
}

export default useHasOverFlown;
