import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { TRANSPORTATION_TAXI, TRANSPORTATION_TYPE } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  GAP,
  PatientFormSubheading,
  TextAreaWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const Transportation = () => {
  const translations = useTranslations();

  const TAXI_OPTIONS: RadioOptionV2[] = [
    {
      id: TRANSPORTATION_TAXI.WHEELCHAIR,
      value: TRANSPORTATION_TAXI.WHEELCHAIR,
      label: translations.patientForms.medicalForm.sectionSix.wheelChair,
    },
    {
      id: TRANSPORTATION_TAXI.STRETCHER,
      value: TRANSPORTATION_TAXI.STRETCHER,
      label: translations.patientForms.medicalForm.sectionSix.stretcher,
    },
    {
      id: TRANSPORTATION_TAXI.LYING_DOWN,
      value: TRANSPORTATION_TAXI.LYING_DOWN,
      label: translations.patientForms.medicalForm.sectionSix.lyingDown,
    },
  ];

  const TRANSPORT_OPTION: RadioOptionV2[] = [
    {
      id: TRANSPORTATION_TYPE.PUBLIC,
      value: TRANSPORTATION_TYPE.PUBLIC,
      label:
        translations.patientForms.medicalForm.sectionSix.optionPublicTransport,
    },
    {
      id: TRANSPORTATION_TYPE.CAR,
      value: TRANSPORTATION_TYPE.CAR,
      label: translations.patientForms.medicalForm.sectionSix.car,
    },
    {
      id: TRANSPORTATION_TYPE.TAXI,
      value: TRANSPORTATION_TYPE.TAXI,
      label: translations.patientForms.medicalForm.sectionSix.taxi,
      subForm: (
        <RadioGroupV2
          elementName="transport_type_taxi_specification"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionSix.taxiOptionLabel
          }
          options={TAXI_OPTIONS}
          formControlLabelSx={{
            paddingBottom: sizing(0.5),
            marginLeft: sizing(4),
          }}
          formLabelSx={visuallyHidden as SxProps}
          radioSx={RADIO_WHITE_BACKGROUND}
        />
      ),
    },
    {
      id: TRANSPORTATION_TYPE.AMBULANCE,
      value: TRANSPORTATION_TYPE.AMBULANCE,
      label: translations.patientForms.medicalForm.sectionSix.ambulance,
      subForm: (
        <TextAreaWithLabel
          elementName="transport_type_ambulance_description"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionSix
              .ambulanceDescriptionLabel
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionSix.transportation}
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="transport_type"
        label={translations.patientForms.medicalForm.sectionSix.transportation}
        options={TRANSPORT_OPTION}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
        sideMutation={(_value, mutation) => {
          mutation(null, "transport_type_ambulance_description");
          mutation(null, "transport_type_taxi_specification");
        }}
      />
      <YesNoRadioWithLabel
        elementName="require_accompanying_person"
        formLabelSx={{ padding: padding(GAP, 0, 1) }}
        label={
          translations.patientForms.medicalForm.sectionSix.accompanyingPerson
        }
        startWithNo
      />
    </>
  );
};
