import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { REHAB_ADMISSION } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  PatientFormSubheading,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
  TextAreaWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const RehabAdmission = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: REHAB_ADMISSION.DIRECT,
      value: REHAB_ADMISSION.DIRECT,
      label: translations.patientForms.medicalForm.sectionOne.optionDirect,
    },
    {
      id: REHAB_ADMISSION.WITHIN_TWO_WEEKS,
      value: REHAB_ADMISSION.WITHIN_TWO_WEEKS,
      label: translations.patientForms.medicalForm.sectionOne.withinTwoWeeks,
      subForm: (
        <DatePickerWithLabel
          bold
          elementName="rehab_admission_within_two_weeks_start_date"
          flatModel
          inputSx={{
            width: SMALL_INPUT_WIDTH,
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          label={
            translations.patientForms.medicalForm.sectionOne
              .earliestDatePossible
          }
          wrapperSx={{ width: "100%", marginBottom: sizing(2) }}
        />
      ),
    },
    {
      id: REHAB_ADMISSION.OUTSIDE_TWO_WEEKS,
      value: REHAB_ADMISSION.OUTSIDE_TWO_WEEKS,
      label: translations.patientForms.medicalForm.sectionOne.outsideTwoWeeks,
      subForm: (
        <>
          <DatePickerWithLabel
            bold
            elementName="rehab_admission_outside_two_weeks_start_date"
            flatModel
            inputSx={{
              width: SMALL_INPUT_WIDTH,
              minWidth: SMALL_INPUT_MIN_WIDTH,
            }}
            label={
              translations.patientForms.medicalForm.sectionOne
                .earliestDatePossible
            }
            wrapperSx={{ width: "100%", padding: padding(0, 0, 1) }}
          />
          <TextAreaWithLabel
            bold
            elementName="rehab_admission_outside_two_weeks_explanation"
            flatModel
            label={
              translations.patientForms.medicalForm.sectionOne.medicalReasons
            }
            marginOverride={margin(0, 0, 1)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionOne.admissionRehab}
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="rehab_admission_type"
        label={
          translations.patientForms.generalForm.sectionI
            .accessibilityGuardianType
        }
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
        sideMutation={(_value, mutation) => {
          mutation(null, "rehab_admission_outside_two_weeks_explanation");
          mutation(null, "rehab_admission_outside_two_weeks_start_date");
          mutation(null, "rehab_admission_within_two_weeks_start_date");
        }}
      />
    </>
  );
};
