import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PERFORMED_TREATMENT } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  PatientFormSubheading,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const PerformedTreatments = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: PERFORMED_TREATMENT.SURGERY,
      value: PERFORMED_TREATMENT.SURGERY,
      label: translations.patientForms.medicalForm.sectionTwo.surgeries,
      subForm: (
        <VerticalLayout
          gap={sizing(1)}
          overflow="visible"
          margin={margin(0, 0, 0, 4.5)}
        >
          <HorizontalLayout gap={sizing(2)}>
            <DatePickerWithLabel
              bold
              elementName="operation_date"
              flatModel
              label={
                translations.patientForms.medicalForm.sectionTwo.surgeryDate
              }
            />
            <TextInputWithLabel
              bold
              elementName="operations"
              flatModel
              label={
                translations.patientForms.medicalForm.sectionTwo.surgeryOps
              }
            />
          </HorizontalLayout>
          <TextAreaWithLabel
            bold
            elementName="operation_description"
            flatModel
            label={
              translations.patientForms.medicalForm.sectionTwo
                .surgeryDescription
            }
            marginOverride={margin(0)}
          />
          <YesNoRadioWithLabel
            elementName="wound_closed"
            flatModel
            label={translations.patientForms.medicalForm.sectionTwo.wound}
          />
        </VerticalLayout>
      ),
    },
    {
      id: PERFORMED_TREATMENT.OTHER,
      value: PERFORMED_TREATMENT.OTHER,
      label: translations.patientForms.medicalForm.sectionTwo.otherTreatments,
      subForm: (
        <TextAreaWithLabel
          bold
          elementName="current_therapy"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionTwo
              .treatmentsDescription
          }
          marginOverride={margin(0)}
        />
      ),
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionTwo.performedTreatments}
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="performed_treatment"
        label={
          translations.patientForms.medicalForm.sectionTwo.performedTreatments
        }
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
        sideMutation={(_value, mutation) => {
          mutation(null, "current_therapy");
          mutation(null, "wound_closed");
          mutation(null, "operation_description");
          mutation(null, "operations");
          mutation(null, "operation_date");
        }}
      />
    </>
  );
};
