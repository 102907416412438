import Paper from "@mui/material/Paper";
import { MenuProps } from "react-select";
import { SelectOption } from "./types";

export function Menu({
  children,
  innerProps,
  selectProps,
}: MenuProps<SelectOption>) {
  return (
    <Paper
      square
      className={selectProps.classes.paper}
      {...(innerProps as any)}
    >
      {children}
    </Paper>
  );
}
