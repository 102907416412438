import { useEffect } from "react";

export const useOnEnter = () => {
  useEffect(() => {
    if (window.$crisp)
      window.$crisp.push([
        "set",
        "session:data",
        [[["url", window.location.href]]],
      ]);
  }, []);
};
