import { ApolloError, ServerError } from "@apollo/client";

export type ApolloNetworkErrorResponse = {
  networkError?: ServerError;
};

export const isError = (err: unknown): err is Error => {
  return err instanceof Error;
};

export const getError = (err: unknown): Error => {
  if (isError(err)) {
    return err;
  } else {
    return new Error("Generic error");
  }
};

export const isApolloError = (err: unknown): err is ApolloError => {
  return err instanceof ApolloError;
};

export const getApolloError = (err: unknown): ApolloError => {
  if (isApolloError(err)) {
    return err;
  } else {
    return new ApolloError({ errorMessage: "Generic error" });
  }
};

export const getApolloNetworkErrorMessage = (err: unknown) => {
  const error = getApolloError(err) as ApolloNetworkErrorResponse;

  const errorMessage =
    typeof error?.networkError?.result === "object"
      ? error.networkError.result.error
      : error?.networkError?.result;

  return errorMessage || JSON.stringify(err);
};

export const getErrorMessage = (err: unknown): string => {
  return getError(err).message;
};
