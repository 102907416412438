import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { TYPE_OF_REHAB } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, sizing } from "ds_legacy/materials/metrics";
import {
  PatientFormSubheading,
  TextAreaWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const RehabType = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: TYPE_OF_REHAB.MOBILE,
      value: TYPE_OF_REHAB.MOBILE,
      label: translations.patientForms.medicalForm.sectionFive.mobile,
    },
    {
      id: TYPE_OF_REHAB.MOBILE_OUTPATIENT,
      value: TYPE_OF_REHAB.MOBILE_OUTPATIENT,
      label: translations.patientForms.medicalForm.sectionFive.mobileOutpatient,
      subForm: (
        <TextAreaWithLabel
          elementName="recommended_reha_type_ambulant_mobil"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionTwo
              .complicationsDescriptionLabel
          }
          marginOverride={margin(0, 0, 1)}
        />
      ),
    },
    {
      id: TYPE_OF_REHAB.STATIONARY,
      value: TYPE_OF_REHAB.STATIONARY,
      label: translations.patientForms.medicalForm.sectionFive.static,
      subForm: (
        <TextAreaWithLabel
          elementName="recommended_reha_type_stationary"
          flatModel
          label={
            translations.patientForms.medicalForm.sectionTwo
              .complicationsDescriptionLabel
          }
          marginOverride={margin(0, 0, 1)}
        />
      ),
    },
  ];

  return (
    <>
      <PatientFormSubheading withBottomPadding>
        {translations.patientForms.medicalForm.sectionFive.typeOfRehab}
      </PatientFormSubheading>
      <RadioGroupV2
        elementName="recommended_reha_type"
        label={translations.patientForms.medicalForm.sectionFive.typeOfRehab}
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioSx={RADIO_WHITE_BACKGROUND}
        sideMutation={(_value, mutation) => {
          mutation(null, "recommended_reha_type_stationary");
          mutation(null, "recommended_reha_type_ambulant_mobil");
        }}
      />
    </>
  );
};
